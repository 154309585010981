import { confirm } from "./popups.js";

const inputId = '#BookingViewModel_DetailsViewModel_Details_LeadPassenger_Address_AddressLookup';

const init = function () {
	var input = $(inputId);
	var address1 = $('input[data-name="address1"]');

	if (input.length > 0) {
		if (!address1.val()) {
			$('div.addressFields').hide();
			$('div.lookupFields').show();
			input.autocomplete({
				source: function (request, response) { addressSearch(input, request, response); },
				select: function (event, ui) { addressSelect(input, event, ui); }
			}).attr('autocomplete', 'autocomplete_off');
		}
		else {
			manualEntry();
		}
	}
	else {
		manualEntry();
	}
	$('a.formLookupManual').click(manualEntry);
}

const addressSearch = function (input, request, response) {
	var form = input.closest('form');
	var countryId = form.find('select[data-name="addressCountry"]').val();
	$.ajax({
		url: input.attr('data-find-url'),
		data: { term: request.term, countryId: countryId, lastId: input.lastId },
		success: function (data) {
			var items = [];
			for (var i = 0; i < data.length; i++) {
				items.push({ value: data[i].Text, label: data[i].Text, next: data[i].Next, id: data[i].Id });
			}
			response(items);
		},
		error: function (e) {
			confirm('Error', 'Sorry, it is not currently possible to lookup your address.<br />Please enter your address manually.', '#', true);
			manualEntry();
		}
	});
}

const addressSelect = function (input, event, ui) {
	if (ui.item.next == 'Find') {
		input.lastId = ui.item.id;
		setTimeout(function () {
			input.autocomplete('search', ui.item.value);
		}, 1);
	}
	else {
		$.ajax(
			{
				url: input.attr('data-retrieve-url'),
				data: { id: ui.item.id },
				success: function (data) {
					var form = input.closest('form');

					form.find('.addressFields').show();
					form.find('.lookupFields').hide();
					form.find('input[data-name="addressCompany"]').val(data.Company);
					form.find('input[data-name="address1"]').val(data.Address1);
					form.find('input[data-name="address2"]').val(data.Address2);
					form.find('input[data-name="address3"]').val(data.Address3);
					form.find('input[data-name="addressTown"]').val(data.Town);

					if (data.Country !== null && data.Country !== undefined) {
						var country = form.find('select[data-name="addressCountry"]');
						country.val(data.Country);
					}

					form.find('input[data-name="addressPostcode"]').val(data.Postcode);
					return false;
				},
				error: function () {
					confirm('Error', 'Sorry, it is not currently possible to lookup your address.<br />Please enter your address manually.', '#', true);
					manualEntry();
				}
			}
		);
	}
}

const manualEntry = function () {
	$('div.addressFields').show();
	$('div.lookupFields').hide();
	$(inputId).val('Address found');
}


export default init;
